@font-face {
    font-family: 'Open-Sans-light';
    src: url('../fonts/OpenSans-Light.eot');
    src: url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Light.woff2') format('woff2'),
        url('../fonts/OpenSans-Light.woff') format('woff'),
        url('../fonts/OpenSans-Light.ttf') format('truetype'),
        url('../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open-Sans-medium';
    src: url('../fonts/OpenSans-Medium.eot');
    src: url('../fonts/OpenSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Medium.woff2') format('woff2'),
        url('../fonts/OpenSans-Medium.woff') format('woff'),
        url('../fonts/OpenSans-Medium.ttf') format('truetype'),
        url('../fonts/OpenSans-Medium.svg#OpenSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open-Sans-regular';
    src: url('../fonts/OpenSans-Regular.eot');
    src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/OpenSans-Regular.woff') format('woff'),
        url('../fonts/OpenSans-Regular.ttf') format('truetype'),
        url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open-Sans-bold';
    src: url('../fonts/OpenSans-Bold.eot');
    src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans-Bold.woff') format('woff'),
        url('../fonts/OpenSans-Bold.ttf') format('truetype'),
        url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open-Sans-semibold';
    src: url('../fonts/OpenSans-SemiBold.eot');
    src: url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../fonts/OpenSans-SemiBold.woff') format('woff'),
        url('../fonts/OpenSans-SemiBold.ttf') format('truetype'),
        url('../fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@import './variable.scss';

* {margin: 0; padding: 0;}
body {font-family:$font-regular; width: 100%;}
a { text-decoration: none; color: $primary; }
a:hover {text-decoration: none; color: $secondary;}
.guest-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .guest-container-inner {
        max-width: 500px;
        text-align: center;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}


